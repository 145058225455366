/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Cable} from '../../types/components/ApiTypes';
import Constants from '../Constants';
import { displayTemp } from './functions';
import getMaxNumberOfSensors from './getMaxNumberOfSensors';
import getSensorColor from './getSensorColor';
import getTempUnit from './getTempUnit';

const getSensorValue = (cable: Cable, index: number, temp: boolean = false) => {
    if (cable.sensorCount > index) {
        if (temp) {
            return cable.sensors.slice().reverse()[index].error ? Constants.NA
                    : `${ displayTemp( getTempUnit() === 'F', cable.sensors.slice().reverse()[index].temperature) } ${ getTempUnit() }`;
        }
        else {
            if (cable.sensors.slice().reverse()[index].sensorMuted) {
                return `${ cable.sensors.slice().reverse()[index].rh } %rh`;
            }
            return `${ cable.sensors.slice().reverse()[index].moistureContent } %`;
        }
    }
    return Constants.NA;
};

const getSensorHighThreshold = (cable: Cable, index: number, temp: boolean = false) => {
    const key = temp ? 'thresholdHigh' : 'CGCHigh';
    return cable.sensorCount > index && cable.sensors.slice().reverse()[index][key];
};

const getSensorColorForCable = (rowIndex: number, cables: Cable[],
    highTemperatureThreshold: number, lowTemperatureThreshold: number) => {
    for (let i = 0; i < cables.length; i++) {
        if (cables[i].sensorCount > rowIndex) {
            //Sensors are displayed in reverse order. So, get the actual array index for the rowIndex.
            const newIndex = cables[i].sensorCount - rowIndex - 1;
            return getSensorColor(cables[i].sensors[newIndex].temperature, cables[i].sensors[newIndex].sensorMuted,
                highTemperatureThreshold, lowTemperatureThreshold);
        }
    }
};

const getRow = (cables: Cable[], index: number,
    highTemperatureThreshold: number, lowTemperatureThreshold: number) => {
    let rowStr = '';
    let tempCables: Cable[];
    cables.forEach(cable => {
        rowStr += `"${ cable.smartId }_column":"${ getSensorValue(cable, index, true)
        }:${ getSensorValue(cable, index) }",`;
        rowStr += `"temp${ cable.smartId }High":${ getSensorHighThreshold(cable, index, true) },`;
        rowStr += `"moist${ cable.smartId }High":${ false },`;
        tempCables = [];
        tempCables.push(cable);
        rowStr += `"sensor${ cable.smartId }Color":"${ getSensorColorForCable(index, tempCables,
            highTemperatureThreshold, lowTemperatureThreshold) }",`;
    });
    return JSON.parse(`{${ rowStr.slice(0, rowStr.length - 1) }}`);
};

const getSensorTableRowData = (cables: Cable[],
    highTemperatureThreshold: number, lowTemperatureThreshold: number) => {
    const numberOfRows = getMaxNumberOfSensors(cables);
    const rows = [];
    for (let i = numberOfRows - 1; i >= 0; i--) {
        rows.push({
            id: i,
            row: i + 1,
            ...getRow(cables, i, highTemperatureThreshold, lowTemperatureThreshold),
        });
    }
    return rows;
};

export default getSensorTableRowData;
