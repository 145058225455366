/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useState} from 'react';
import {styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import BaseModal from '../../common/modal/BaseModal';
import TextFieldInput from '../../common/input/TextFieldInput';
import SelectFieldInput from '../../common/input/SelectFieldInput';
import postMachineLeariningTraining from '../../../controllers/apiCalls/post/postMachineLearningTraining';
import { useSnackbar } from 'notistack';

const Container = styled('div')({
    width: '500px',
    padding: '16px',
});

interface Props {
    open: boolean,
    close: () => void,
    structureId: number,
    startTime: number,
    endTime: number,
    sensorId?: number,

}

const MLTagDetailModal = observer((props: Props) => {
    const [ note, setNote ] = useState('');
    const [ noteError, setNoteError ] = useState('');
    const [ observation, setObservation ] = useState('other');
    const [ observationError, setObservationError ] = useState('');

    const {enqueueSnackbar} = useSnackbar();

    const submit = () => {
        var body = {
            structId : props.structureId,
            sensorId: props.sensorId,
            start: props.startTime,
            end: props.endTime,
            observation: observation.toString(),
            notes: note,
        };
        postMachineLeariningTraining(enqueueSnackbar,body);
        props.close();
    };

    return (
        <BaseModal
            id="ml_tag_note"
            open={props.open}
            close={props.close}
            submit={submit}
            title='Machine Learning Training Candidate'
            disableSubmitOnAwait
        >
            <Container>
                Structure ID: {props.structureId}<br/>
                Start: {String(props.startTime)}<br/>
                End: {String(props.endTime)}<br/>
                Sensor ID: {String(props.sensorId)}<hr/>
                <SelectFieldInput
                    id="type_input"
                    label="Observation"
                    field={{
                        value: observation,
                        setValue: setObservation,
                        error: observationError,
                        setError: setObservationError,
                    }}
                    options={[
                        {
                            id: 'other',
                            value: 'other',
                            title: 'Other',
                        },
                        {
                            id: 'fan',
                            value: 'fan_started',
                            title: 'Fan appears to have started running',
                        },
                        {
                            id: 'fan',
                            value: 'fan_stopped',
                            title: 'Fan appears to have stopped running',
                        },
                        {
                            id: 'exposed',
                            value: 'sen_exposed',
                            title: 'Sensors appear to have been uncovered',
                        },
                        {
                            id: 'covered',
                            value: 'sen_covered',
                            title: 'Sensors appear to have been covered',
                        },
                        {
                            id: 'temp',
                            value: 'temp_rise',
                            title: 'Grain temperature rising',
                        },
                        {
                            id: 'unexpected',
                            value: 'sen_unexpected',
                            title: 'Unexpected sensor readings/changes',
                        },
                        {
                            id: 'error',
                            value: 'sen_error',
                            title: 'Apparent Sensor Error',
                        },
                    ]}
                />
                <TextFieldInput
                    id="ml_tag_note"
                    label="Notes"
                    field={{
                        value: note,
                        setValue: setNote,
                        error: noteError,
                        setError: setNoteError,
                    }}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 255,
                        },
                        multiline: true,
                        rows: 5,
                    }}
                />
            </Container>
        </BaseModal>
    );
});

export default MLTagDetailModal;
