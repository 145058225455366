/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import AreaChart from '../../../../common/charts/AreaChart';
import { BinGraphData } from '../../../../../types/components/ApiTypes';
import Colors from '../../../../../helper/Colors';
import { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';

interface Props {
    data: BinGraphData[],
    id: string,
    legend_show: boolean,
    height: string | number,
    setTimeUnit: Dispatch<SetStateAction<string>>
    setStart: Dispatch<SetStateAction<moment.Moment>>
    setEnd: Dispatch<SetStateAction<moment.Moment>>
    sensorId?: number,
}

const ViewDeviceMoistureChart = (props: Props) => {
    const series = [
        {
            name: 'Avg',
            type: 'line',
            data: props.data.map(dataPoint => [ dataPoint.dt, dataPoint.avgmc ]),
        },
        {
            name: 'Max',
            type: 'line',
            data: props.data.map(dataPoint => [ dataPoint.dt, dataPoint.maxmc ]),
        },
        {
            name: 'Min',
            type: 'line',
            data: props.data.map(dataPoint => [ dataPoint.dt, dataPoint.minmc ]),
        },
    ];

    const SensorSeries = [
        {
            name: 'Sensor',
            type: 'line',
            data: props.data.map(dataPoint => [ dataPoint.dt, dataPoint.moisture ]),
        },
    ];

    const yaxis: ApexYAxis[] = [
        {
            seriesName: 'Moisture (%)',
            title: {
                text: 'Moisture (%)',
            },
            opposite: false,
            labels: {
                  minWidth: 80,
                  maxWidth: 80,
            },
        },
    ];

    const gstroke : ApexStroke = {};
    gstroke.width = [ 3,2,2 ];
    gstroke.curve = 'smooth';
    gstroke.dashArray = [ 0,0,0 ];

    const popt : ApexPlotOptions = {};

    useEffect(() => {
        setTimeout(() => {
            const chart = ApexCharts.getChartByID('moisturechart');
            if (chart && props.sensorId === -1) {
                chart?.toggleSeries('Min');
            }
        }, 0);
    }, []);

    return (
        <AreaChart
            series={(props.sensorId === -1) ? series : SensorSeries}
            yaxis={yaxis}
            legendPosition="bottom"
            showLegend={props.legend_show}
            id={props.id}
            type="line"
            showXAxis={true}
            height={props.height}
            stroke={gstroke}
            colors={[ Colors.avgMoistureColor, Colors.maxMoistureColor, Colors.primary ]}
            plotOptions={popt}
            setTimeUnit={props.setTimeUnit}
            setStart={props.setStart}
            setEnd={props.setEnd}
            sensorId = {props.sensorId}
        />
    );
};

export default ViewDeviceMoistureChart;
