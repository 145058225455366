/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Theme, XAxisType } from '../../../types/components/ApiTypes';
import { observer } from 'mobx-react-lite';
import WebStore from '../../../stores/WebStore';
import TileSkeleton from '../TileSkeleton';
import { ApexOptions } from 'apexcharts';
import getBinGraphData from '../../../controllers/apiCalls/get/getBinGraphData';
import Constants from '../../../helper/Constants';
import moment from 'moment';
import getSensorBinGraphData from '../../../controllers/apiCalls/get/getSensorBinGraphData';
import getSiteGraphData from '../../../controllers/apiCalls/get/getSiteGraphData';

interface Props {
    series: {
        name: string,
        type?: string,
        data: number[][] | { x: string; y: (() => number)[]; }[] |
        { x: string; y: (number | (() => number))[]; }[],
    }[],
    yaxis?: ApexYAxis[],
    title?: string,
    legendPosition?: 'right' | 'bottom' | 'left' | 'top' | undefined,
    showLegend?: boolean,
    id: string,
    type?: 'area' | 'line' | 'bar' | 'histogram' | 'pie' | 'donut' | 'radialBar' | 'scatter' |
        'bubble' | 'heatmap' | 'candlestick' | 'boxPlot' | 'radar' | 'polarArea' |
        'rangeBar' | 'treemap' | undefined
    showXAxis?: boolean,
    height: string | number
    stroke: ApexStroke
    colors?: string[]
    plotOptions: ApexPlotOptions
    group?: string
    xAxis? : ApexXAxis
    showMenu? : boolean
    showTooltip? : boolean
    tooltipXFormat? : string
    showDataLabels? : boolean
    dataLabelFormatter? : (val: string | number | number[], opts?: any) => string | number
    timeUnit? : string
    setTimeUnit : Dispatch<SetStateAction<string>>
    setEnd: Dispatch<SetStateAction<moment.Moment>>
    setStart: Dispatch<SetStateAction<moment.Moment>>,
    sensorId?: number,
    isSiteChart?: boolean,
}

var tooltipLocation = 'bottomRight';
var switching = false;

const AreaChart = observer((props: Props) => {
    const [ loading, setLoading ] = useState(false);
    const [ options, setOptions ] = useState<ApexOptions>({
        chart: {
            id: props.id,
            group: props.group || 'syncgroup',
            toolbar: {
                tools: {
                    selection: false,
                    zoom: props.showMenu || true,
                    zoomin: props.showMenu || false,
                    zoomout: props.showMenu || false,
                    pan: false,
                    reset: false,
                    download: false,
                },
                show: props.showMenu || false,
            },
            selection: {
                enabled: false,
            },
            height: props.height,
            fontFamily: 'Public Sans',
            background: WebStore.lightMode ? '#FFF' : '#2c2c2c',
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: false,
                zoomedArea: {
                    fill: {
                        color: '#006600',
                        opacity: 0.4,
                    },
                },
            },
            events: {
                zoomed: (chartContext: any, config: any) => {
                    if (props.isSiteChart) {
                        getSiteGraphData(null, WebStore.selectedSiteId,
                            Math.floor(config.xaxis.min), Math.floor(config.xaxis.max));
                    } else if (props.sensorId && props.sensorId !== -1) {
                        getSensorBinGraphData(null, WebStore.viewDeviceId, props.sensorId, Math.floor(config.xaxis.min),
                            Math.floor(config.xaxis.max));
                    } else {
                        getBinGraphData(null, WebStore.viewDeviceId, Math.floor(config.xaxis.min),
                            Math.floor(config.xaxis.max));
                    }
                    props.setTimeUnit(Constants.CUSTOM);
                    props.setStart(moment(Math.floor(config.xaxis.min)));
                    props.setEnd(moment(Math.floor(config.xaxis.max)));
                },
                mouseMove: (event: any, chartContext: any) => {
                    if (!switching && event.target.scrollWidth > 0 &&
                        event.offsetX > (event.target.scrollWidth / 1.5)) {
                        if (chartContext.opts.tooltip.fixed.position == 'bottomRight') {
                            switching = true;
                        [ 'tempchart', 'moisturechart', 'fanchart', 'grainchart',
                        'alertchart', 'voltagechart', 'rhchart' ].forEach(chartid => {
                        ApexCharts.exec(chartid,'updateOptions', {
                            tooltip: {
                                fixed: {
                                    position: 'bottomLeft',
                                },
                            },
                        });
                    });
                    chartContext.opts.tooltip.fixed.position = 'bottomLeft';
                    setTimeout( () => { switching = false; }, 300);
                    }
                    }
                    if (!switching && event.target.scrollWidth > 0 &&
                        event.offsetX < (event.target.scrollWidth / 2.5)) {
                        if (chartContext.opts.tooltip.fixed.position == 'bottomLeft') {
                        switching = true;
                        [ 'tempchart', 'moisturechart', 'fanchart', 'grainchart',
                        'alertchart', 'voltagechart', 'rhchart' ].forEach(chartid => {
                            ApexCharts.exec(chartid,'updateOptions', {
                            tooltip: {
                                fixed: {
                                    position: 'bottomRight',
                                },
                            },
                        });
                    });
                    chartContext.opts.tooltip.fixed.position = 'bottomRight';
                    setTimeout( () => { switching = false; }, 300);
                }
                }
                },
            },
        },
        colors: props.colors,
        dataLabels: {
            enabled: props.showDataLabels || false,
            formatter : props.dataLabelFormatter || undefined,
            textAnchor: 'middle',
            style: {
                fontFamily: 'Public Sans',
            },
            dropShadow: {
                enabled: true,
                left: 2,
                top: 2,
                opacity: 0.8,
            },
        },
        stroke: props.stroke,
        xaxis: props.xAxis || {
            type: XAxisType.dateTime,
            tooltip: {
                enabled: false,
            },
            labels: {
                show: props.showXAxis,
            },
        },
        yaxis: props.yaxis,
        legend: {
            position: props.legendPosition,
            show: props.showLegend,
        },
        theme: {
            mode: WebStore.lightMode ? Theme.light : Theme.dark,
        },
        title: {
            text: props.title,
            align: 'left',
        },
        plotOptions: props.plotOptions,
        tooltip: {
            enabled: props.showTooltip || true,
            shared: true,
            x: {
                format: props.tooltipXFormat || 'yy/MM/dd HH:mm',
                show: props.showMenu || false,
            },
            fillSeriesColor: true,
            theme:  WebStore.lightMode ? Theme.light : Theme.dark,
            style: {
                fontFamily: 'Public Sans',
            },
            fixed: {
                enabled: true,
                position: tooltipLocation,
            },
        },
    });

    useEffect(() => {
        setLoading(true);
        setOptions({
            ...options,
            chart: {
                ...options.chart,
                background: WebStore.lightMode ? '#FFF' : '#2c2c2c',
            },
            xaxis: {
                ...options.xaxis,
            },
            yaxis: props.yaxis,
            theme: {
                mode: WebStore.lightMode ? Theme.light : Theme.dark,
            },
        });
        setTimeout(() => setLoading(false), 0);
    }, [ WebStore.lightMode, props.yaxis ]);

    if (loading) {
        return <TileSkeleton />;
    }
    return (
        <Chart
            options={options}
            series={props.series}
            id = {props.id}
            type={props.type}
            width="100%"
            height={props.height}
        />
    );
});

export default AreaChart;
