import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';
import WebStore from '../../../stores/WebStore';

const getSensorBinGraphData = (enqueueSnackbar: any, structId: number,  sensorId: number,
        start: number, end: number) => {
    callAPI(`binSensorGraphData/${ structId }/${ sensorId }/${ start }/${ end }/${ WebStore.utcOffset }`,
        (response) => {
            BinsenseStore.setBinGraphData(response);
        },
        enqueueSnackbar
    );
};

export default getSensorBinGraphData;