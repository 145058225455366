/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {DistancePerPixel, MarkerData, StructIdSerials} from '../../../../types/components/ApiTypes';
import IconMarker from './IconMarker';
import TooltipMarker from './TooltipMarker';
import DisplayMarker from './DisplayMarker';
import SiteMarker from './SiteMarker';
import { Dispatch, SetStateAction } from 'react';

interface Props {
    marker: MarkerData,
    onMarkerEdit?: (newMarker: MarkerData) => void,
    onMarkerDelete?: (id: number) => void,
    distancePerPixel?: DistancePerPixel,
    masterSerials: string[],
    automationMasterSerials: string[],
    index: number,
    siteId?: number,
    disableAutomation: boolean,
    markerData: MarkerData[],
    markerSerials: StructIdSerials[],
    siteSerials?: StructIdSerials[],
    deletedStructureArray?: any[],
    setDeletedStructureArray?: Dispatch<SetStateAction<number[]>>,
}

const CustomMarker = (props: Props) => {
    if (props.marker.siteTooltip) {
        return <SiteMarker marker={props.marker}/>;
    }

    if (props.marker.tooltip) {
        return (
            <TooltipMarker
                marker={props.marker}
                distancePerPixel={props.distancePerPixel}
                onMarkerEdit={props.onMarkerEdit}
                onMarkerDelete={props.onMarkerDelete}
                index={props.index}
                masterSerials={props.masterSerials}
                automationMasterSerials={props.automationMasterSerials}
                siteId={props.siteId}
                disableAutomation={props.disableAutomation}
                markerData={props.markerData}
                markerSerials={props.markerSerials}
                siteSerials={props.siteSerials}
                deletedStructureArray={props.deletedStructureArray}
                setDeletedStructureArray={props.setDeletedStructureArray}
            />
        );
    }

    if (props.marker.icon) {
        return (
            <IconMarker marker={props.marker}/>
        );
    }

    if (props.marker.display) {
        return (
            <DisplayMarker
                marker={props.marker}
                distancePerPixel={props.distancePerPixel}
            />
        );
    }

    return null;
};

export default CustomMarker;
