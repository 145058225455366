/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Customer, MarkerData} from '../../types/components/ApiTypes';
import Constants from '../Constants';

const getMyCustomersMarkerData = (customers: Customer[]) => {
    const markerData: MarkerData[] = [];
    customers.forEach(customer => customer.sites.forEach(site => markerData.push({
            id: site.id,
            longitude: site.longitude,
            latitude: site.latitude,
            siteTooltip: {
                name: site.name,
                customer: {
                    id: customer.id,
                    firstName: customer.account?.firstName ? customer.account.firstName : Constants.NA,
                    lastName: customer.account?.lastName ? customer.account.lastName : Constants.NA,
                    email: customer.account?.accountEmail ? customer.account.accountEmail : Constants.NA,
                },
                displayData: {
                    deviceType: Constants.LIVE,
                    status: 'Online',
                },
            },
    })));
    return markerData;
};

export default getMyCustomersMarkerData;
