/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import DisplayMap from '../../common/map/DisplayMap';
import WebStore from '../../../stores/WebStore';
import Constants from '../../../helper/Constants';
import BinsenseStore from '../../../stores/BinsenseStore';
import {Customer} from '../../../types/components/ApiTypes';
import getMyCustomersMarkerData from '../../../helper/functions/getMyCustomersMarkerData';
import {useMemo, useState} from 'react';
import getIndex from '../../../helper/functions/getIndex';
import {observer} from 'mobx-react-lite';
import DashboardSkeleton from '../../common/DashboardSkeleton';

interface Props {
    customers: Customer[]
}

const MyCustomersMap = observer((props: Props) => {
    const [ viewState, setViewState ] = useState({
        longitude: -104.4,
        latitude: 55.8,
        zoom: 3,
    });
    const markerData = useMemo(() => getMyCustomersMarkerData(props.customers), [ props.customers ]);

    const onMarkerClick = (siteId: number) => {
        const markerIndex = getIndex(siteId, markerData);
        // @ts-ignore
        const userId = markerData[markerIndex].siteTooltip.customer.id;
        WebStore.setPageIds([ userId, siteId, -1 ], true);
        const userIndex = getIndex(userId, BinsenseStore.customerSiteData);
        WebStore.setPageData([ BinsenseStore.customerSiteData,
            BinsenseStore.customerSiteData[userIndex].sites, null ], true);
        WebStore.setStorageMode(Constants.MAP, true);
        WebStore.goToNewPage(Constants.STORAGE);
    };

    const onMove = (e: any) => {
        setViewState(e.viewState);
    };

    return !BinsenseStore.awaitingUserData ? (
        <DisplayMap
            height={400}
            initialView={viewState}
            markers={markerData}
            toggleOnBottom
            onMarkerClick={onMarkerClick}
            onMove={onMove}
        />
    ) : (
        <DashboardSkeleton height={400}/>
    );
});

export default MyCustomersMap;
